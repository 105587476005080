//Importamos la clase JS para las traducciones
import { TraduccionesJavascript } from '/build/js/general/traduccionesJavascript.js';
// import { PagosPayPal } from '/build/js/general/pagosPayPal.js';
let traducciones;
let servicioReservadoId;
let precioFinal;
let paypalInit = true;
let locale;

//Primero cargamos las traducciones
const cargador = new TraduccionesJavascript();

    // Llamamos a traducciones y manejamos la promesa
    cargador.traducciones()

    .then(translations => {
        
        traducciones = translations;
       
        iniciarApp();

    })
    .catch(error => {

        traducciones = null

        iniciarApp();

        console.error('Error en la solicitud de traducciones:');
        // Maneja el caso en el que no se pudieron cargar las traducciones

    });

function iniciarApp(){

    ApiServicios();
  
    
    //Variables calendario
    //const locale = navigator.language;

    const fragment = document.createDocumentFragment();
    //Mapeamos los meses para añadir la traducción
    let nombresDeMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    let meses = nombresDeMeses.map(nombre => traducciones[nombre] || nombre);

    let numeroDias = "";
    let domAvisoFecha = false;
    const checkBoxAvisoLegal = document.querySelector('.form__check--legal');
    const formFooterPagos = document.querySelector('.form__footer--pagos');
    //saber los dias de cada mes Cuando le indicas 0 en el parámetro de días al constructor, lo interpreta como "el día anterior al 1" es decir el último día del mes pasado
    //let diasDeCadaMes = new Date(2028, 2, 0).getDate();

    //Obtener año/mes/día

    let fecha = new Date();
    let anioActual = fecha.getFullYear();
    let mesActual = fecha.getMonth();
    let mesSeleccionado = fecha.getMonth()+1;
   
    let contadorMeses = 0;
    let diaActual = fecha.getDate();

    //Fin variables calendario

    const cita = {

        dia:'',
        mes:mesActual+1,
        anio:anioActual,
        categoria:'',
        hora:'',
        servicioId:'',
        nombre:'',
        precio:'',
        precio_oferta:'',
        tiempo:'',
        horaId:'',
        fecha:''

    }

    let horasDisponibles = [];

    const controlScrollCalendarioCitas = document.getElementById('control__calendario__citas');
    const primerPaso = document.getElementById('paso-1');
    const segundoPaso = document.getElementById('paso-2');
    const tercerPaso = document.getElementById('paso-3');
    const campoHoras = document.querySelector('.campo-horas__disponibles');
    let divHora;
    const anio = document.getElementById('anio');
    const mes = document.getElementById('mes');
    const dias = document.getElementById('dia');
    const botonEnvio = document.getElementById('boton__envio--cita')
    const botonEnvioPago = document.getElementById('boton__envio--cita--pago');


    const botonAtras = document.getElementById('anterior');

    botonAtras.addEventListener('click', function() {

        primerPaso.classList.remove('ocultar__seccion');
        segundoPaso.classList.add('ocultar__seccion');
        tercerPaso.classList.add('ocultar__seccion');
        botonAtras.classList.add('ocultar__seccion');
        controlScrollCalendarioCitas.classList.add('ocultar__seccion');
        botonEnvioPago.classList.remove('ocultar__seccion');
        botonEnvioPago.disabled = false;
        paypalInit = true;
        if(checkBoxAvisoLegal){
            checkBoxAvisoLegal.classList.remove('ocultar__seccion');
        }
        

        //Eliminamos el form de paypal
        const paypalButtons = document.querySelector('.paypal-buttons');
        if(paypalButtons){
            paypalButtons.remove();
        }
        //campoHoras.classList.add('ocultar__seccion');
        //botonSalir.classList.remove('ocultar__seccion');
        const removerDiasCalendario = document.querySelectorAll('.form__dia--dom');
        removerDiasCalendario.forEach(e=>{
        e.remove();
      
        })
    
        cita.servicioId = '';
        cita.nombre = '';
        cita.precio = '';
        cita.precio_oferta = '';
        cita.tiempo = '';
        cita.categoria = '';
    
    borrarHoras(); 
        

    });
    async function ApiServicios(){
  
        try {
            
            //const url = 'http://127.0.0.1:8000/api/servicios';
            //Hosting
            const url = 'https://www.tarotyvidentebuena.com/api/servicios';

            
            const $resultado = await fetch(url);
        
            const servicios = await $resultado.json();
           
            mostrarServicios(servicios);    
    
        } catch (error) {
            console.log('consulta no realizada');
            console.log(error);
        }
    }
    
    function mostrarServicios(servicios){
 
        const consulta = document.getElementById('servicios');
    
        servicios.forEach((servicio)=>{
       
            //Cogemos la informacion de los parametros solo para mostrar los servicios
            // const urlParams = new URLSearchParams(window.location.search);
            // const servicioParam = urlParams.get('servicio');
            // const servicioParamId = urlParams.get('id');
            // const servicioCategoriaId = urlParams.get('categoriaId');
     
            //Utilizamos categotiaId para coger todas las consultas de tarot, las otras las cogemos del servicio.id
                //if(servicioParamId == servicio.id || servicioCategoriaId == servicio.categoriaId){
                    const enlaceServicio = document.createElement('A');
                    enlaceServicio.setAttribute('href','#control__calendario__citas')
                    const tipoConsulta = document.createElement('P');
                    tipoConsulta.textContent=traducciones[servicio.nombre] || servicio.nombre;
                   
                    const precioConsulta = document.createElement('P');
                    precioConsulta.textContent=`${servicio.precio}€`;
                    const precioOferta = document.createElement('P');
                    precioOferta.textContent=`${servicio.precio_oferta}€`;

    
                    const servicioDiv = document.createElement('DIV');
                    servicioDiv.classList.add('citas__clientes--servicios--listado--servicio');
    
                    //le damos un data atribute con el id para poder seleccionarlo
                    servicioDiv.dataset.idServicio = servicio.id;
                    servicioDiv.onclick = function(e){

                    //Aqui validamos el precio final

                       //Validar el precio si la oferta es vigente inferior a l'actual
                            
                            if(servicio.precio_oferta>1){

                                if(servicio.fecha_final_oferta){
                                
                                    if(fechaOfertaMilisegundos >= fechaMilisegundos){
                                        
                                        precioFinal = servicio.precio_oferta;
                                    }else{

                                        precioFinal = servicio.precio;

                                    }

                                }else{

                                    precioFinal = servicio.precio_oferta;
                                }

                            }else{
                                precioFinal = servicio.precio;
                            }
                        
    
                    // Desestructuración del objeto servicio
                    const {
                        id: servicioId,
                        nombre,
                        precio,
                        tiempo,
                        precio_oferta: precio_oferta,
                        categoria
                    } = servicio;
        
                    // Rellenamos el objeto cita usando desestructuración
                    Object.assign(cita, {
                        servicioId,
                        nombre,
                        precio,
                        precio_oferta,
                        tiempo,
                        categoria
                    });
                        primerPaso.classList.add('ocultar__seccion');

                        controlScrollCalendarioCitas.classList.remove('ocultar__seccion');
                      
                        segundoPaso.classList.remove('ocultar__seccion');
    
                        botonAtras.classList.remove('ocultar__seccion');
    
                        //Rellenamos la etiqueta h2 con el servicio
                        const servicioSeleccionado = document.querySelector('.citas__clientes--calendario--servicio--seleccionado')
                        servicioSeleccionado.textContent = traducciones[cita.nombre] || cita.nombre;

    
                        calendario();
                        seleccionMes();
                       
                    }
    
                    servicioDiv.appendChild(tipoConsulta);
                    //Fechas, converimos la fecha del servicio en un objeto DATE y luego las dos a milisegundos
                    
                    let fechaOferta = new Date(servicio.fecha_final_oferta);
                    let fechaMilisegundos = fecha.setHours(0, 0, 0, 0);
                    fechaMilisegundos = fecha.getTime();
                    let fechaOfertaMilisegundos = fechaOferta.getTime();

                    const textoOferta = document.createElement('P');
                    const fechaFinalOferta = document.createElement('P');
                    const textoFecha = document.createElement('P');
                    textoOferta.textContent = traducciones['en oferta!!!'] || 'en oferta!!!';
                    textoFecha.textContent = traducciones['valida hasta'] || 'valida hasta';

                    // Formatear la fecha en d-m-Y
                    const dia = fechaOferta.getDate();
                    const mes = fechaOferta.getMonth() + 1; // Los meses comienzan desde 0
                    const anio = fechaOferta.getFullYear();
                    const fechaFormateada = `${dia}-${mes}-${anio}`;
            

                    fechaFinalOferta.textContent = fechaFormateada;
                 
                    if(servicio.precio_oferta>1){

                        if(servicio.fecha_final_oferta){
                         
                            if(fechaOfertaMilisegundos >= fechaMilisegundos){
                                
                                servicioDiv.appendChild(precioConsulta);
                                servicioDiv.appendChild(textoOferta);
                                servicioDiv.appendChild(precioOferta);
                                servicioDiv.appendChild(textoFecha);
                                servicioDiv.appendChild(fechaFinalOferta);
                                precioConsulta.classList.add('seccion__precios--precio--tachado')

                            }else{

                                servicioDiv.appendChild(precioConsulta);

                            }

                        }else{

                            servicioDiv.appendChild(precioConsulta);
                            servicioDiv.appendChild(textoOferta);
                            servicioDiv.appendChild(precioOferta);
                            precioConsulta.classList.add('seccion__precios--precio--tachado')
                        }

                    }else{
                        servicioDiv.appendChild(precioConsulta);
                    }
                    enlaceServicio.appendChild(servicioDiv);
                    consulta.appendChild(enlaceServicio);
    
                //}
    
        })//fin del forEach
    
    }
    /************** Mostrar calendario ******************/
       
      function calendario(mesSeleccionado){
            
        //Obtenemos los días de cada mes
        
        if(mesSeleccionado != null){
        
            mesActual=mesSeleccionado-1;
            const pRemove = document.querySelectorAll('.form__dia--dom');
             pRemove.forEach(e=>{
                e.remove()
            })
            
        }
    
        numeroDias = new Date(anioActual, mesActual+1, 0).getDate();
    
        //Obtenemos el dia que empieza el mes. acuerdate que es un array y noviembre es mes 10
        let diaDeLaSemana = new Date(anioActual, mesActual, 1).getDay();
        if(diaDeLaSemana===0)diaDeLaSemana=7
        
        
        anio.textContent = anioActual;
        mes.textContent =`${meses[mesActual]}`;
            
        let dia
        for (let i = 1; i < numeroDias+1; i++) {
    
            //Comprobar el dia de la semana en que empieza el calendario
                while ((diaDeLaSemana-1)>0) {
                    
                    dia = document.createElement('P'); 
                    dia.classList.add('form__dia--dom');
                    dia.textContent = "";
                    fragment.appendChild(dia);
                    diaDeLaSemana--
                                
                }
                let enlace = document.createElement('A');
                enlace.setAttribute('href','#campo-hora__cita')
                dia = document.createElement('P');
                dia.classList.add('form__dia--dom');
                enlace.textContent = i;
                dia.setAttribute('data__fecha',i)
                dia.appendChild(enlace);
                fragment.appendChild(dia);
    
            }
            dias.appendChild(fragment);
    
            if(contadorMeses===0){
                for (let i = 1; i < diaActual+1; i++) {
                
                    let diaInvalido = document.querySelector(`[data__fecha="${i}"]`);
                    diaInvalido.style.backgroundColor = '#c4ece5';
            
                }
                let contador = diaActual+1
            
                while (contador<=numeroDias) {
                    let diaValido = document.querySelector(`[data__fecha="${contador}"]`);
                    diaValido.onclick = function(){
                      
                        seleccionDia(diaValido);
                       
                    }
                    contador++;
                }
            }else{
    
                for (let i = 1; i <= numeroDias; i++) {
    
                    let diaValido = document.querySelector(`[data__fecha="${i}"]`);
                    diaValido.onclick = function(){
                      
                    seleccionDia(diaValido);
                }
            
            }
    
        }
          
    }
        /************** Selección mes ****************/
    
    function seleccionMes(){
           
        let pulsador = document.querySelectorAll('.pulsador')
    
        pulsador[0].onclick = atras;
        pulsador[1].onclick = adelante;
    
        function adelante(){
              mesSeleccionado = mesActual+1;
              
            if(mesSeleccionado>11){
                
                mesSeleccionado=1;
                anioActual++;
    
            }else{
            
                mesSeleccionado ++;
            }
          
            cita.anio = anioActual;
            cita.mes = mesSeleccionado;
                
            contadorMeses++
            calendario(mesSeleccionado);   
            borrarHoras();
        
        }
        function atras(){
                
            mesSeleccionado = mesActual+1;
                
            if(contadorMeses>0){
                
                if(mesSeleccionado==1){
                    
                    mesSeleccionado=12;
                    anioActual--;
    
                }else{
    
                    mesSeleccionado --;
    
                }
                    contadorMeses --;
                    cita.anio = anioActual;
                    cita.mes = mesSeleccionado;
                    calendario(mesSeleccionado);
                    borrarHoras();
                    //eliminarDomAvisoFecha()
                }
                
            }
       
    }
    
    /************** Selección día ****************/
    
    function seleccionDia(diaValido){
    
        if(domAvisoFecha)eliminarDomAvisoFecha()
            
        const diaClass = document.querySelector('.fecha__seleccionada');
        cita.dia = diaValido.getAttribute('data__fecha');
          
        //pintamos la fecha
        if(diaClass){
            diaClass.classList.remove('fecha__seleccionada');
        }
        diaValido.classList.add('fecha__seleccionada');
        //console.log(cita)//El objeto llega lleno
        //Aqui llamamos la funcion de la API Horas
    
        //Comprobamos si hay una hora en el objeto, si hay una es que se ha cambiado el mes o el dia
      
        if(cita.hora){
            validarHora(cita.hora);
        }
        
        iniciarAppHoras()
    }
    
    //API HORAS
    
    function borrarHoras(){
    
        //Si hay horas borramos todas las horas y quitamos la clase despliegue
        const horasAnteriores = document.querySelectorAll('.hora');
        const contenedorHorasAnteriores = document.querySelector('#campo-hora__cita')
    
        if(horasAnteriores){
    
            contenedorHorasAnteriores.classList.remove('despliegue__horas');
    
            horasAnteriores.forEach(e=>{
                e.remove();
            })
            cita.hora = '';
            cita.horaId = '';
    
          
            //Borramos el innerHTML del resumen
            var elementosGenerados = document.querySelectorAll('.html__reservas__generado');
    
            if(elementosGenerados){
    
                elementosGenerados.forEach(function(elemento) {
                    elemento.parentNode.removeChild(elemento);
                });
    
                //Agregamos la classe ocultar
                tercerPaso.classList.add('ocultar__seccion');
                
            }
    
        }
    
    }//Fin borrarHoras
    async function iniciarAppHoras(){
    
        borrarHoras();
        //El método padStart() asegura que la cadena tenga una longitud mínima, y si no la tiene, añade ceros a la izquierda hasta que alcance esa longitud. En este caso, asegura que mes y dia siempre tengan dos dígitos.
        const fechaCita = `${cita.anio}-${String(cita.mes).padStart(2, '0')}-${String(cita.dia).padStart(2, '0')}`;
    
        //Ponemos la fecha en el objeto
        cita.fecha = fechaCita;
    
        const servicio = cita.servicioId;
        
        try{
            //Pasamos la fecha como parte de la url y la recuperamos en la Route
            //const url =`http://127.0.0.1:8000/api/cliente/citas/horas?fecha=${fechaCita}&servicio=${servicio}`;

            //Hosting
            const url =`https://www.tarotyvidentebuena.com/api/cliente/citas/horas?fecha=${fechaCita}&servicio=${servicio}`;
           
           
            const $resultado = await fetch(url);
           
            horasDisponibles = await $resultado.json();
          
            mostrarHorasDisponibles();
           
            
        }catch(error){
    
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: traducciones['Ha ocurrido un error al identificar el servicio, vuelve a intentarlo'] || 'Ha ocurrido un error al identificar el servicio, vuelve a intentarlo',
                button: 'ok'
                
                }).then(()=>{
                
                    window.location.reload();//Con el then lo hacemos porque es una sync function y cuando pulsen ok recarga la pagina
        
            })
    
        }
    
    }//fin iniciarAppHoras
    
    function mostrarHorasDisponibles() {
    
        campoHoras.classList.add('despliegue__horas');
    
        //Borramos horas si cambian el día
        if(divHora){
            const borrarHoras = document.querySelectorAll('.hora')
            borrarHoras.forEach(e=>{
                e.remove();
            })
           
        }
        
    
        /********mostramos todas las horas disponibles************** */
        //Como viene un objeto lo convertimos en un array clave valor
    
        if(Object.entries(horasDisponibles).length<1){
            divHora = document.createElement('P');
            divHora.classList.add('citas__clientes--sin--hora');
            divHora.classList.add('hora');
            divHora.textContent =  traducciones['No hay horas disponibles para este día'] || 'No hay horas disponibles para este día';
            campoHoras.appendChild(divHora);
        } 
        Object.entries(horasDisponibles).forEach(([id, hora]) => {
            divHora = document.createElement('A');
            divHora.classList.add('hora');
            divHora.setAttribute('href','#paso-3')
            
            // Aquí puedes usar tanto el ID como la hora.
            // Por ejemplo, si quieres usar el ID como data-attribute:
            divHora.setAttribute('data-id', id);
            divHora.setAttribute('data-hora', hora);
    
            divHora.onclick = function(hora) {
        
                const activarHoras = document.querySelector('.hora__seleccionada');
               
                if(activarHoras){
                   
                    activarHoras.classList.remove('hora__seleccionada');
                }
    
                
                hora.target.classList.add('hora__seleccionada');
    
                cita.hora = hora.target.dataset.hora
                cita.horaId = hora.target.dataset.id
                
    
                validarHora();
            };
            divHora.textContent = hora; // o si quieres mostrar el ID también: `${id}: ${hora}`
            
            campoHoras.appendChild(divHora);
    
        });
    }//Fin mostrarHorasDisponibles
    
    function validarHora(){
       
        //Comprobar que el id de la hora exista
        //cita.horaId = 14
        //Comprobar usando el método 'hasOwnProperty'
    
        if (horasDisponibles.hasOwnProperty(cita.horaId)) {
        
            //Mostrar cita
           
            tercerPaso.classList.remove('ocultar__seccion');
            const resumenCita = document.getElementById('cliente__resumen__cita');

            //Con el opreador or || nos aseguramos de que si no hay traducción ponga el string español
            const contenidoHTML = `
            <p class="html__reservas__generado">${traducciones['Servicio'] || 'Servicio'}: <span>${cita.nombre}</span></p>
            <p class="html__reservas__generado">${traducciones['Fecha'] || 'Fecha'}: <span>${cita.dia}/${cita.mes}/${cita.anio}</span></p>
            <p class="html__reservas__generado">${traducciones['Hora'] || 'Hora'}: <span>${cita.hora}</span></p>
            <p class="html__reservas__generado">${traducciones['Precio'] || 'Precio'}: <span>${precioFinal}€</span></p>
            `
    
            // Asigna la cadena al innerHTML del elemento
            resumenCita.innerHTML = contenidoHTML;

           
    
            //Poner a la escucha el boton de envio
          
            document.addEventListener('click',e=>{

                locale = window.location.pathname.split('/')[1];

                //deshabilitar botones
                if(e.target === botonEnvio){

                    botonEnvio.disabled = true;
                    //Desabilitar si existe la clase dom-ready
                    let contenedorPayPal = document.getElementById('cliente-pagos')
                    contenedorPayPal.remove();
                      //Locale idioma
       
                    guardarCita(locale);

                }else if(e.target === botonEnvioPago){

                    if(paypalInit){

                        const divPagos = document.querySelector('.seccion__cliente--pagos');
                     
                        divPagos.style.padding = '2.5%'; 
                        
                        //capturamos el aviso legal
                        let avisoLegalCheckbox = document.querySelector('input[name="aviso_legal"]');
                        if( avisoLegalCheckbox){
                            let alerta = document.getElementById('alerta');

                            let avisoLegal = avisoLegalCheckbox.checked ? 'si' : 'no';
    
                            if(!avisoLegalCheckbox.checked) {
                             
                                alerta.classList.remove('ocultar__seccion');
    
                            } else {
                              
                                alerta.classList.add('ocultar__seccion');
                                checkBoxAvisoLegal.classList.add('ocultar__seccion')
    
                                inicializarBotonPayPal(locale,avisoLegal);
    
                                paypalInit = false;
                               
                            }
                        }else{

                            let avisoLegal = 'si';
                            inicializarBotonPayPal(locale,avisoLegal);
    
                            paypalInit = false;
                        }
                       
                        
                    }
                 

                }
            
            })
             
        } else {
    
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: traducciones['La hora ya no está disponible, por favor inténtalo de nuevo'] || 'La hora ya no está disponible, por favor inténtalo de nuevo',
               
            })
        
        }
    
    }//Fin Validar Hora
    
    async function guardarCita(locale){

        botonEnvioPago.disabled=true;

        //Locale idioma
        //const locale = window.location.pathname.split('/')[1];
       
        const token = document.querySelector('input[name="_token"]').value
            
        const {fecha ,hora, servicioId} = cita;
        servicioReservadoId = servicioId;
        
        //Pasamos los datos de esta forma y no con formData para poder pasar el boolean de pago
        const datos = {
            fecha: fecha,
            hora: hora,
            servicio: servicioId,
            locale: locale
        };
        mostrarLoaderReserva()
        try {
            //peticion a la api
            //const url = 'http://127.0.0.1:8000/area-cliente/citas/reservar';
            //hosting
            const url = 'https://www.tarotyvidentebuena.com/area-cliente/citas/reservar';
           
            const respuesta = await fetch(url, {//estamos diciendo que vamos utilizar el metodo post hacia la url
                method: 'POST',
                body: JSON.stringify(datos),
                headers: {
                    'X-CSRF-TOKEN': token, // Incluye el token CSRF en los headers de la solicitud
                    'Content-Type': 'application/json' //Importante si no utilizamos formData
                },
                mode: "same-origin"
               
                //con el body le decimos a fetch que los datos del post estan en datos
            });
            
            const resultado = await respuesta.json();
            //console.log(resultado)
           
            if(resultado.success){
                ocultarLoaderReserva();
                Swal.fire({
                icon: 'success',
                title: traducciones['Cita Creada'],
                text: traducciones['Tu cita fue creada correctamente y te llegará un email de confirmación'] || 'Tu cita fue creada correctamente y te llegará un email de confirmación',
                button: 'ok'
                
                }).then(()=>{
                
                    window.location.reload();
            
                })
    
            }
            if (resultado.error) {

                ocultarLoaderReserva();
                showError(resultado.message)

                .then(() => {

                    window.location.reload();

                });
            }
            
            
        } catch (error) {

            showError(message)
            
            .then(()=>{
                
                window.location.reload();
    
            });
    
        }
    
    }//Fin guardar cita

    function inicializarBotonPayPal(locale, avisoLegal) {

         
        formFooterPagos.classList.remove('ocultar__seccion');
       
        const token = document.querySelector('input[name="_token"]').value;
 
        botonEnvioPago.disabled=true;
        botonEnvioPago.classList.add('ocultar__seccion');

        const payerConfig = emailUsuario ? { email_address: emailUsuario } : {};

        paypal.Buttons({

            style: {
                layout: 'vertical',
                color:  'white',
                shape:  'pill',
                label:  'checkout',
                height: 50,
                fundingicons: true,
            },
        
    
            createOrder: function(data, actions) {
            
                return actions.order.create({
                    application_context: {  // Eliminamos los campos de envío
                        shipping_preference: "NO_SHIPPING"
                    }, 
                    payer:payerConfig,
                    purchase_units:[{
                        amount:{
                            value:precioFinal
                        },
                        
                    }]
                });
            },
            onApprove: function(data, actions){
                mostrarLoaderPago()
                //console.log('orderID:', data.orderID, 'payerID:', data.payerID);
                //console.log(data)
                return fetch('/paypal/process',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': token, // Incluye el token CSRF en los headers de la solicitud
                    },
                    body: JSON.stringify({
                        
                        orderID: data.orderID,
                        payerID: data.payerID,
                        servicioId: cita.servicioId,
                        fechaSeleccionada: cita.fecha,
                        horaSeleccionada: cita.hora,
                        locale: locale,
                        nombreServicio: cita.nombre,
                        avisoLegal: avisoLegal
                    
                    })
                })

                .then(res => {
                
                    if (!res.ok) {
                        console.log(res)
                    
                        ocultarLoaderPago();
                        showError('No se pudo realizar el pago, vuelve a intentarlo por favor' ||'No se pudo realizar el pago, vuelve a intentarlo por favor');
                        
                    }
                    return res.json();
                })
                
                .then (function(response) {

                    console.log(response)
                    //Si la respuesta en erronea
                    if(!response.success){
                    
                        ocultarLoaderPago();
                        showError(traducciones[response.message] ||'No se pudo realizar el pago, vuelve a intentarlo por favor');
                        return res.json();
                    }
                    ocultarLoaderPago();
                    Swal.fire({
                        icon: 'success',
                        title: traducciones['Pago exitoso'],
                        text: traducciones['Tu cita fue creada correctamente y se ha realizado el pago, te llegará un email de confirmación conforme tienes la reserva hecha. Espero tu llamada. Muchas gracias.'] || 'Tu cita fue creada correctamente y se ha realizado el pago, te llegará un email de confirmación conforme tienes la reserva echa. Espero tu llamada. Muchas gracias.',
                        button: 'ok'
                        
                        }).then(()=>{
                        
                            window.location.reload()
                    
                        })
                
                })              
    
            }
        
        }).render('#paypal-button-container');

     

    }

}//Fin iniciarApp



function showError(message) {

    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        button: 'ok'
    })
}

function mostrarLoaderPago() {
    document.getElementById('loader-pago').style.display = 'flex';
}
              
function ocultarLoaderPago() {
    document.getElementById('loader-pago').style.display = 'none';
}
    
function mostrarLoaderReserva() {
    document.getElementById('loader-reserva').style.display = 'flex';
}
  
function ocultarLoaderReserva() {
    document.getElementById('loader-reserva').style.display = 'none';
}

  